<!--
* @description
* This component is used to display  a title at the top and highlighted styles for article or a list of articles that will be passed like children.
* It is used in the frontpage and paper theme's articles's, featured articles.
  -->

<template>
  <div
    class="rounded-md md:rounded-xl bg-darkerblue text-white px-4 md:px-10 py-3.5 md:py-8"
  >
    <div class="pb-3.5">
      <NuxtLink
        v-if="props.link"
        :to="props.link"
        class="flex items-center gap-3 group"
      >
        <span class="font-sans font-bold text-base leading-6 md:text-2xl">
          {{ props.title }}</span
        >
        <CommonFontAwesomeIcon
          class="text-white text-sm transition-transform duration-200 ease-in-out transform group-hover:translate-x-2"
          icon="fa-regular fa-chevron-right"
        />
      </NuxtLink>
      <span v-else class="font-sans font-bold text-base leading-6 md:text-2xl">
        {{ props.title }}</span
      >
    </div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string
    link?:
      | string
      | {
          name: string
          params: Record<string, string | undefined>
        }
  }>(),
  {
    link: undefined,
  }
)
</script>
