<template>
  <div class="mt-5 md:mt-8">
    <div class="container no-print flex flex-col gap-y-4 md:gap-y-8 mb-10">
      <LayoutDkFrontpage
        :articles="topArticleSection"
        sponsored-teaser-placement-id="0"
      >
        <ListJobs b2c :count="8" :random="true" />
      </LayoutDkFrontpage>

      <AdsConceptAd
        class="no-print hidden lg:block"
        id="altinget-lb2"
        :full-width="true"
      />
      <AdsConceptAd
        class="no-print lg:hidden order-15"
        id="altinget-mob2"
        :full-width="true"
      />

      <template v-if="articleSections && articleSections.length > 1">
        <div v-for="(restArticles, index) in restArticleSections" :key="index">
          <LayoutDkFrontpage
            :articles="restArticles"
            :sponsored-teaser-placement-id="(index + 1).toString()"
          />
        </div>
      </template>
      <div class="flex justify-center" v-if="displayLoadMoreButton">
        <CommonButton @click="onLoadMoreClick">
          {{ $t('ShowMore') }}
        </CommonButton>
      </div>
      <NewsletterSignup class="mt-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'
const config = useRuntimeConfig()
const requestUrl = useRequestURL()

const currentOffset = ref(0)
const currentCount = ref(16)
const displayLoadMoreButton = ref<boolean>(true)

const { data: articles, loadMore } = await useArticles({
  paper: 1,
  frontend: true, // Flags to use Saga API
  count: currentCount.value,
  offset: currentOffset.value,
})

const articleSections = computed(() => {
  if (!articles.value?.length) {
    return []
  }
  const chunkSize = 8
  return articles.value.reduce<ContentArticle[][]>(
    (sections, article, index) => {
      const chunkIndex = Math.floor(index / chunkSize)
      if (!sections[chunkIndex]) {
        sections[chunkIndex] = [] // Initialize a new chunk
      }
      sections[chunkIndex].push(article)
      return sections
    },
    []
  )
})

const topArticleSection = computed(() => {
  return articleSections.value?.[0] ?? []
})
const restArticleSections = computed(() => {
  return articleSections.value?.filter((_, index) => index > 0)
})

const onLoadMoreClick = async () => {
  await loadMore(16)
}

useHead(() => {
  // Have to use config.public.site here, due to composables not working in useHead
  const siteConfig = config.public.site

  return createBasicHeadMetaData(siteConfig, requestUrl)
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-500;
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
.fade-enter-to,
.fade-leave-from {
  @apply opacity-100;
}
</style>
