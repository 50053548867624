<!--
 The TeaserPrimaryPlacement component is designed to display an article teaser with a large image.
 Desktop and mobile: column layout (image and title under it)
 modileSmall prop is used to display articles with small image (row view) on mobile devices.
 Options:
 - Podcast: if type is Podcast, the B2CPodcastTeaser component is displayed
 - FrontpageTheme: if type is FrontpageTheme, the B2CArticleThemeTeaserFrame component is displayed
 - Article: if type is Article, the B2CArticleTeaser component is displayed
-->
<template>
  <B2CPodcastTeaser
    v-if="
      props.article.Type && getArticleType(props.article.Type) === 'podcast'
    "
    :article="props.article"
    :is-first-article="true"
    :preload="props.preloadFirstImage"
  />
  <B2CArticleTeaser
    v-else-if="
      !props.article.FrontpageTheme &&
      !props.article.FeaturedArticle &&
      !props.article.SponsoredArticle
    "
    :key="'article' + 'first'"
    :article="props.article"
    :index="0"
    :lazy="props.lazy"
    :preload="props.preloadFirstImage"
    :mobile-small="props.modileSmall"
  />

  <B2CArticleThemeTeaserFrame
    v-else-if="
      props.article.FrontpageTheme &&
      props.article.FrontpageTheme.Articles?.length > 0
    "
    :key="'article FrontpageTheme' + 0"
    :title="props.article.FrontpageTheme.Title"
  >
    <B2CArticleThemeTeaser
      :article="props.article.FrontpageTheme.Articles[0]"
      :index="0"
    />
    <div class="flex flex-col gap-6 overflow-y-auto custom-scrollbar max-h-56">
      <B2CArticleThemeTeaser
        v-for="(article, index) in props.article.FrontpageTheme.Articles.slice(
          1
        )"
        :article="article"
        :index="index + 1"
      />
    </div>
  </B2CArticleThemeTeaserFrame>
</template>
<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const props = withDefaults(
  defineProps<{
    article: ContentArticle
    lazy?: boolean
    preloadFirstImage?: boolean
    modileSmall?: boolean
  }>(),
  {
    lazy: true,
    preloadFirstImage: false,
    modileSmall: false,
  }
)
</script>
